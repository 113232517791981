<template>
  <div style="height:600px;overflow-y:scroll;">
    <el-select v-model="value.field">
      <el-option
        v-for="item in fields"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-color-picker v-model="value.startColor"></el-color-picker>
    <el-color-picker v-model="value.endColor"></el-color-picker>
  </div>
</template>

<script>
export default {
  name: 'AutoFillConfigurator',
  props: {
    fields: {
      type: Array
    },
    value: {
      type: Object
    }
  },
  mounted () {
    // console.log('в mounted', this.value)
  }
}
</script>

<style scoped>

</style>
